<!--
 * @Description: 生产商商首页
 * @Author: zhang zhen
 * @Date: 2023-02-13 16:08:54
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-23 17:26:18
 * @FilePath: /page-sass/src/views/workSpace/SupplierHome.vue
-->
<template>
  <div class="workSpace">
    <div class="userInfo-card">
      <div class="userInfo-right">
        <a-avatar
          :src="setUserInfo().avatar || '/avatar.png'"
          style="background-color: #fff; width: 56px; height: 56px; margin-right: 15px"
        />
        <div class="right-box" :style="!Authentication?' flex-direction: column;align-items: flex-start;height: 56px;':''">
          <div class="username-info">
            <div class="username">{{ !Authentication ? setUserInfo().phone || '' : username }}</div>
            <div  class="tag" v-if="!Authentication">未认证</div>
          </div>
          <div class="desc" v-if="!Authentication">账号尚未认证，认证完成后即可使用平台功能</div>
<!--          <div class="tags-list" v-else>-->
<!--            <div class="tags-list-item" v-for="row in leftTagList" :key="row">-->
<!--              <span>-->
<!--                <a-tooltip slot="suffix" :title="row">-->
<!--                  {{ row }}-->
<!--                </a-tooltip>-->
<!--              </span>-->
<!--            </div>-->
<!--&lt;!&ndash;            <img src="~@/assets/editTitle.png" @click="handleEditTags" alt="" class="editTag" />&ndash;&gt;-->
<!--          </div>-->
        </div>
      </div>
      <div class="userInfo-left">
        <div class="action-info" @click="handleInitTour">
          <span class="text">新手引导</span>
          <img src="/info.png" alt="" class="icon" />
        </div>
        <a-button type="primary" class="btn" v-if="!Authentication" @click="handleJump">立即认证</a-button>
      </div>
    </div>
    <div class="center-part">
      <div class="space-title">待办事项</div>
      <div class="part-container">
        <div class="card-box">
          <div class="card-box-title">
            <span class="label">报价动态</span>
            <a-tooltip placement="bottomLeft" :getPopupContainer="(node) => node.parentNode" :arrowPointAtCenter="true">
              <template slot="title">
                <div class="container-tooltip">
                  <div class="title">报价动态</div>
                  <div class="desc-item">待报价：等待报价的需求单总数量</div>
                  <div class="desc-item">已报价：已参与报价的需求单总数量</div>
                </div>
              </template>
              <img src="/info.png" alt="" class="icon" />
            </a-tooltip>
          </div>
          <div class="data-view-box">
            <div class="data-card quoteData" @click="handleChangeQuoteParams({ status: [3] })">
              <div class="label-top">
                <span class="text">待报价</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.notQuoted || 0 }}</div>
            </div>
            <div class="data-card quotedPrice"  @click="handleChangeQuoteParams({ status: [0] })">
              <div class="label-top">
                <span class="text">已报价</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.quoted || 0 }}</div>
            </div>
          </div>
        </div>
        <div class="card-box right-card">
          <div class="card-box-title">
            <span class="label">订单动态</span>
            <a-tooltip placement="bottomLeft" :getPopupContainer="(node) => node.parentNode" :arrowPointAtCenter="true">
              <template slot="title">
                <div class="container-tooltip">
                  <div class="title">订单动态</div>
                  <div class="desc-item">合同待签署：已达成合作，订单合同等待签署的总数量</div>
                  <div class="desc-item">生产中：正在生产中的订单总数量</div>
                  <div class="desc-item">已发货：已发货的订单总数量</div>
                  <div class="desc-item">待收款：等待收款的订单总数量</div>
                  <div class="desc-item">待评价：等待评价的订单总数</div>
                </div>
              </template>
              <img src="/info.png" alt="" class="icon" />
            </a-tooltip>
          </div>
          <div class="data-view-box" >
            <div class="data-card signCount" @click="handleChangeOrderParams({ status: ['WAIT_SIGN'] })">
              <div class="label-top">
                <span class="text">协议待签署</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.contractPending || 0 }}</div>
            </div>
            <div class="data-card productCount" @click="handleChangeOrderParams({ status: ['PRODUCING'] })">
              <div class="label-top">
                <span class="text">生产中</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.production || 0 }}</div>
            </div>
            <div class="data-card paymentCount" @click="handleChangeOrderParams({ status: ['SHIPPED'] })">
              <div class="label-top">
                <span class="text">已发货</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.delivery || 0 }}</div>
            </div>
            <div class="data-card shippingCount"   @click="handleQueryOrder()">
              <div class="label-top">
                <span class="text">待收款</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.paymentPending || 0 }}</div>
            </div>
            <div class="data-card refundCount"  @click="handleChangeOrderParams({ status: ['PAID'] })">
              <div class="label-top">
                <span class="text">待评价</span>
                <img src="/right-base-outline-pc.png" alt="" class="tag" />
              </div>
              <div class="num">{{ dataSum.evaluated || 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-info">
      <a-tabs v-model="activeIndex" @change="handleChangeTab">
        <a-tab-pane key="1" tab="报价动态">
          <a-table
            :columns="columns"
            :scroll="{ x: 'max-content' }"
            :loading="loading"
            :data-source="quoteList"
            :pagination="false"
            style="margin-bottom: 16px"
          >
            <span class="row_title" slot="purchaseNo" slot-scope="text, record">
              <span class="orderName">
                {{ text }}
              </span>
              <img src="/new.png" alt="" class="newIcon" v-if="record.newFlag" />
            </span>
            <template slot="quoteSource" slot-scope="text, record">
              {{ text == 1 ? '主动报价' : '邀请报价' }}
            </template>
            <template slot="quoteDeadline" slot-scope="text, record">
              <div>
                {{ (text && text.substring(0, 10)) || '' }}
              </div>
            </template>
            <template slot="operation" slot-scope="text, record">
              <a @click="handleQuote(record)" v-if="record.status == '3'">立即报价</a>
              <a
                @click="handleQuote(record)"
                v-if="record.status == '0' || (record.status == '-1' && record.isPass != 1)"
                >重新报价</a
              >
              <template v-if="record.status == '0'">
                <a-divider type="vertical" />
                <a @click="handleCancel(record.quoteId)">取消报价</a>
              </template>
              <template v-if="record.status == '1' || record.status == '-1' || record.status == '2'">
                <a-divider
                  type="vertical"
                  v-if="record.status == '0' || (record.status == '-1' && record.isPass != 1)"
                />
                <a @click="handleSwitchTab(record)">详情</a>
              </template>
            </template>
          </a-table>
          <ZPagination
            :total="pageNation.total"
            :current="pageNation.pageNum"
            :pageSize="pageNation.pageSize"
            @handlePageChange="handleChangePage"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="订单动态">
          <a-table
            :columns="columnsA"
            :scroll="{ x: 'max-content' }"
            :loading="loadingA"
            :data-source="orderList"
            :pagination="false"
            style="margin-bottom: 16px"
          >
            <span class="row_title" slot="orderNo" slot-scope="text, record">
              <span class="orderName">
                {{ text }}
              </span>
              <img src="/new.png" alt="" class="newIcon" v-if="record.newFlag" />
            </span>
            <template slot="wrapTypesDictName" slot-scope="text, record">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ text }}</span>
                </template>
                <span>{{ text | ellipsis(6) }}</span>
              </a-tooltip>
            </template>

            <template slot="status" slot-scope="text, record">
              {{ (text && statusDictList[text + '']) || '' }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <a @click="handleSwitchTabB(record)">详情</a>
            </template>
          </a-table>
          <ZPagination
            :total="pageNationA.total"
            :current="pageNationA.pageNum"
            :pageSize="pageNationA.pageSize"
            @handlePageChange="handleChangePageA"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import InfoCardItem from './modules/infoCardItem'
import OrderCardItem from './modules/orderCardItem'
import { mapGetters } from 'vuex'
import { getAction, postAction, postQueryAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
function updateProgress(current, total) {
  document.querySelector('.driver-popover-progress-text').innerText = `${current}/${total}`
  document.querySelector('.driver-popover-close-btn').innerText = '跳过'
}
export default {
  name: 'SupplierHome',
  props: {
    Authentication: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InfoCardItem,
    OrderCardItem,
    EmptyArea,
    ZPagination,
  },
  data() {
    return {
      leftTagList: [],
      loadingA: false,
      tagList: [],
      inputVisible: false,
      inputValue: '',
      loading: false,
      officeList: [],
      orderList: [], // 订单列表
      quoteList: [], // 需求列表
      businessType: '0',
      activeIndex: '1',
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      pageNationA: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      columns: [
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo',
          width: 234,
          scopedSlots: { customRender: 'purchaseNo' },
        },
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle',
          width: 200,
        },
        {
          title: '报价类别',
          dataIndex: 'quoteSource',
          key: 'quoteSource',
          width: 100,
          scopedSlots: { customRender: 'quoteSource' },
        },
        {
          title: '截止报价时间',
          dataIndex: 'quoteDeadline',
          key: 'quoteDeadline',
          scopedSlots: { customRender: 'quoteDeadline' },
          width: 140,
        },

        {
          title: '报价状态',
          dataIndex: 'statusLabel',
          key: 'statusLabel',
          width: 110,
        },
        {
          title: '报价时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 161,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      columnsA: [
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 236,
          scopedSlots: { customRender: 'orderNo' },
        },
        {
          title: '订单名称',
          ellipsis: true,
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 200,
        },
        {
          title: '需求类型',
          dataIndex: 'purchaseTypeDictName',
          key: 'purchaseTypeDictName',
          width: 120,
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypesDictName',
          key: 'wrapTypesDictName',
          scopedSlots: { customRender: 'wrapTypesDictName' },
          width: 160,
        },
        {
          title: '订单金额/元',
          dataIndex: 'orderAmount',
          key: 'orderAmount',
          width: 150,
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          width: 140,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 104,
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
        },
      ],
      memoInfo: null,
      isEdit: false,
      username: '',
      driverObj: null,
      orderParams: {
        excludeStatus: ['FINISHED', 'CANCELED']
      },
      statusDictList: {
        1: '协议待确定',
        2: '待签署',
        3: '待预付',
        4: '待生产',
        5: '生产中',
        6: '已发货',
        7: '已收货',
        8: '已付款',
        9: '已完结',
        '-1': '已取消',
        '-2': '订单已关闭',
      },
      dataSum: {

      },
      orderUrl: '/order/pageList',
      quoteParams: {
        status: [0, 3]
      }
    }
  },
  created() {
    this.handleLoadUserTag()
    this.handleLoadQuoteList() // 加载需求数据
    this.handleLoadOrder()
    this.handleLoadUserInfo()
    this.handleGetAllCounts();
  },
  mounted() {
    let value = sessionStorage.getItem('isFirst')
    this.driverObj = driver({
      overlayColor: '#00000080',
      allowClose: !value,
      showProgress: true,
      nextBtnText: '下一步',
      prevBtnText: '上一步',
      doneBtnText: '完成',
      closeBtnText: '跳过',
      popoverClass: 'driverjs-theme',
      steps: [
        {
          element: '.userInfo-card',
          popover: {
            title: '账号认证',
            description: '认证完成后，即可解锁平台功能',
            side: 'bottom',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(1, 20)
            },
          },
        },
        {
          element: '.backToHome',
          popover: {
            title: '返回首页',
            description: '返回“岭湃客”平台首页',
            side: 'bottom',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(2, 20)
            },
          },
        },
        {
          element: '.sendInfo',
          popover: {
            title: '发布需求',
            description: '一键发布您的采购需求',
            side: 'bottom',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(3, 20)
            },
          },
        },
        {
          element: '.notice-areas',
          popover: {
            title: '消息',
            description: '包含服务消息和系统消息和客户消息',
            side: 'bottom',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(4, 20)
            },
          },
        },
        {
          element: '.graphicalPlane',
          popover: {
            title: '数据面板',
            description: '报价、订单等数据统计分析',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(5, 20)
            },
          },
        },
        {
          element: '.requirementPool',
          popover: {
            title: '公共需求池',
            description: '所有需求信息，可在需求公共对所有需求进行报价。',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(6, 20)
            },
          },
        },
        {
          element: '.requirement',
          popover: {
            title: '需求管理',
            description: '管理发布的需求信息，包含：需求筛选、生产商邀请报价、已参与生产商合作、需求修改、关闭需求',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(7, 20)
            },
          },
        },
        {
          element: '.quotePriceManagement',
          popover: {
            title: '报价管理',
            description: '对生产商报价的管理操作。包含在公共需求池主动报价、采购商邀请报价',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(8, 20)
            },
          },
        },
        {
          element: '.orderManagement',
          popover: {
            title: '订单管理',
            description:
              '与已合作的生产商订单过程追踪。包含：订单查询、合同签署、货款追踪、生产追踪、收发货追踪、票据追踪、售后等管理',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(9, 20)
            },
          },
        },
        {
          element: '.messageManagement',
          popover: {
            title: '消息管理',
            description: '包含服务消息和系统消息和客户消息',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(10, 20)
            },
          },
        },
        {
          element: '.businessManagement',
          popover: {
            title: '企业管理',
            description: '企业认证信息和采购标签维护',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(11, 20)
            },
          },
        },
        {
          element: '.userInfo',
          popover: {
            title: '账号管理',
            description: '账号密码修改、账号绑定的手机号修改',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(12, 20)
            },
          },
        },
        {
          element: '.quoteData',
          popover: {
            title: '报价动态 - 待报价',
            description: '采购商邀请，等待报价的需求单总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(13, 20)
            },
          },
        },
        {
          element: '.quotedPrice',
          popover: {
            title: '报价动态 - 已报价',
            description: '已参与报价的需求单总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(14, 20)
            },
          },
        },
        {
          element: '.signCount',
          popover: {
            title: '订单动态 - 合同待签署',
            description: '已达成合作，订单合同等待签署的总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(15, 20)
            },
          },
        },
        {
          element: '.productCount',
          popover: {
            title: '订单动态 - 生产中',
            description: '正在生产中的订单总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(16, 20)
            },
          },
        },
        {
          element: '.paymentCount',
          popover: {
            title: '订单动态 - 已发货',
            description: '已发货的订单总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(17, 20)
            },
          },
        },
        {
          element: '.shippingCount',
          popover: {
            title: '订单动态 - 待收款',
            description: '等待收款的订单总数量',
            side: 'bottom',
            align: 'start',
            onPopoverRender: () => {
              updateProgress(18, 20)
            },
          },
        },
        {
          element: '.refundCount',
          popover: {
            title: '订单动态 - 待评价',
            description: '等待评价的订单总数量',
            side: 'bottom',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(19, 20)
            },
          },
        },
        {
          element: '.bottom-info',
          popover: {
            title: '动态列表',
            description: '报价动态和订单动态，数据列表具体信息',
            side: 'top',
            align: 'end',
            onPopoverRender: () => {
              updateProgress(20, 20)
            },
          },
        },
      ],
    })
    this.$nextTick(() => {
      if (value) {
        this.handleInitTour();
        setTimeout(() => {
          sessionStorage.removeItem('isFirst')
        }, 500)
      }
    })
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleJump() {
      this.$router.push(`/businessManagement/companyInfo`)
    },
    handleChangeTab(e) {
      if (e == 1) {
        this.handleChangeQuoteParams({ status: [0, 3] })
      } else {
        this.handleChangeOrderParams({ excludeStatus: ['FINISHED', 'CANCELED'] }, 1)
      }
    },
    handleGetAllCounts() {
      getAction('/indexbroswer/item/supplierBusinessCount').then(res => {
        const { success, data } = res;
        if (success) {
          this.dataSum = data
        }
      })
    },
    handleChangeQuoteParams(info) {
      this.activeIndex = '1'
      this.quoteParams = info
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.handleLoadQuoteList();
    },
    handleChangeCategory() {
      this.handleLoadQuoteList()
    },

    handleChangeOrderParams(info, type = 1) {
      this.activeIndex = '2'
      this.orderParams = info;
      this.pageNationA = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.orderUrl = type == 1 ? '/order/pageList' : '/indexbroswer/item/queryNotOrderEvaluation';
      this.handleLoadOrder();
    },
    handleQueryOrder() {
      this.activeIndex = '2'
      this.orderParams = {}
      this.pageNationA = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.orderUrl = '/order/queryOrderPendingPayment'
      this.handleLoadOrder()
    },
    handleEditTags() {
      this.$router.push('/businessManagement/companyInfo')
    },
    handleLoadUserInfo() {
      if (!this.setUserInfo().businessId) return
      this.leftTagList = []
      postQueryAction('/business/queryByBusinessId', { businessId: this.setUserInfo().businessId }).then((res) => {
        const { success, data } = res
        if (success) {
          const { capacity, name } = data
          this.username = name
          if (capacity) {
            const {
              yearTurnoverDictName,
              minOrderCountDictName,
              sampleTestDictName,
              authMethodDictName,
              printingMethodDictName,
              productPackageDictName,
              purchasingMethodDictName,
            } = capacity
            let sampleTestDictNameList = [],
              authMethodDictNameList = [],
              printingMethodDictNameList = []
            if (sampleTestDictName) {
              sampleTestDictNameList = sampleTestDictName.split(',').map((item) => `支持${item}`)
            }
            if (authMethodDictName) {
              authMethodDictNameList = authMethodDictName.split(',').map((item) => `支持${item}`)
            }
            if (printingMethodDictName) {
              printingMethodDictNameList = printingMethodDictName.split(',').map((item) => `支持${item}`)
            }
            // '最小下单量：' + minOrderCountDictName,
            let tagList = [
              ...sampleTestDictNameList,
              ...authMethodDictNameList,
              ...printingMethodDictNameList,
              productPackageDictName,
              purchasingMethodDictName,
            ].filter((item) => !!item)

            if (yearTurnoverDictName) {
              tagList.unshift(`年营业额：${yearTurnoverDictName}`)
            }
            this.leftTagList = tagList.slice(0, 5)
          }
        }
      })
    },
    /* 获取用户标签 */
    handleLoadUserTag() {
      // getAction('/tag/BusinessTagQuery', { userId: this.setUserInfo().userId }).then((res) => {
      //   const { success, data } = res
      //   if (success && data) {
      //     // this.tagList = data.businessLists || []
      //     let labelArr = []
      //     if (!data && !data.businessLists) return
      //     data.businessLists.map((i, index) => {
      //       if (index >= 4) {
      //         labelArr.push(i.dictValue)
      //       } else {
      //         this.tagList.push(i)
      //       }
      //     })
      //     this.memoInfo = labelArr.length ? labelArr.join('、') : null
      //   }
      // })
    },
    /* 删除标签 */
    handleClose({ tagId }) {
      postAction('/tag/user/delete', { tagId }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.handleLoadUserTag()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSwitchTabB(record) {
      const { orderTitle, orderNo } = record
      console.log(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1)
      this.$router.push(
        this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1
          ? `/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}`
          : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}`
      )
    },
    // handleSwitchTab(item) {
    //   const { purchaseId, purchaseTitle, purchaseNo, businesStatus, status } = item
    //   this.$router.push(
    //     `/requirement/ForUserAndPurchaseDetails?purchaseId=${purchaseId}&purchaseTitle=${purchaseTitle}&purchaseNo=${purchaseNo}&activeTab=BasicInfo&formType=${
    //       businesStatus == '0' ? 'product' : businesStatus == '1' ? 'package' : 'plan'
    //     }&status=${status}`
    //   )
    // },
    handleLoadQuoteList() {
      const { pageNum, pageSize } = this.pageNation
      this.loading = true
      this.quoteList = []
      postAction('/quote/list', {
        pageNum,
        pagesSize: pageSize,
        ...this.quoteParams
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.pageNation.total = total
            this.quoteList = list
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 取消报价
    handleCancel(quoteId) {
      postAction('/quote/cancel', {
        quoteId,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success('报价已经取消')
          this.handleLoadQuoteList()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, businessId } = item
      this.$router.push(
        `/quotePriceManagement/quotePriceInfoView?purchaseId=${purchaseId}&quoteId=${quoteId}&isDetail=1&businessId=${businessId}`
      )
    },
    /* 参与报价 */
    handleQuote(item) {
      this.$router.push(
        `/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&quoteSource=${item.quoteSource}&quoteId=${item.quoteId}`
      )
    },
    handleLoadOrder() {
      const { pageNum, pageSize } = this.pageNation
      this.loadingA = true
      // 加载订单数据
      postAction(this.orderUrl, {
        pageNum,
        pagesSize: pageSize,
        ...this.orderParams
      }).then((res) => {
        const { success, data } = res
        this.loadingA = false
        if (success) {
          this.orderList = data.list || []
          this.pageNationA.total = data.total
        }
      }).catch(err => {
        this.loadingA = false
      })
    },
    handleChangePage({ pageNum, pageSize }) {
      this.pageNation.pageNum = pageNum
      this.pageNation.pageSize = pageSize
      this.handleLoadQuoteList()
    },
    handleChangePageA({ pageNum, pageSize }) {
      this.pageNationA.pageNum = pageNum
      this.pageNationA.pageSize = pageSize
      this.handleLoadOrder()
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },

    handleInputChange(e) {
      this.inputValue = e.target.value
    },

    handleInputConfirm() {
      if (!this.inputValue) return this.$message.warning('标签名称不能为空')
      postAction('/tag/user/add', {
        userId: this.setUserInfo().userId,
        tagName: this.inputValue,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          Object.assign(this, {
            inputVisible: false,
            inputValue: '',
          })
          this.handleLoadUserTag()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleImprovingUserInfo() {
      // this.$router.push('/ImproveInformation')
      this.$router.push('/businessManagement/companyInfo')
    },
    handleChangeMessageTab(e) {
      // 消息接口对接
    },
    handleInitTour() {
      this.driverObj.drive()
      // updateProgress(1,  20)
    },
  },
}
</script>

<style lang="less" scoped>
.workSpace {
  display: flex;
  grid-gap: 25px 25px;
  flex-direction: column;
  min-height: calc(100vh - 90px);

  .left-info {
    flex: 1 0 0;
    min-width: 0;

    .topView {
      min-height: 90px;
      display: flex;
      justify-content: space-between;
      grid-gap: 30px 30px;
      margin-bottom: 15px;
      flex-wrap: wrap;

      &-item {
        width: calc((100% - 90px) / 4);
        height: 90px;
        background-color: #f7f8fa;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        align-items: center;

        img.icon {
          width: 64px;
          height: 64px;
          margin-right: 6px;
        }

        .right-view {
          font-size: 12px;
          color: #1d2129;
          line-height: 19px;

          .number {
            font-size: 21px;
            line-height: 30px;
            font-weight: 500;
          }
        }
      }
    }

    ::v-deep .ant-table {
      color: rgba(0, 0, 0, 0.85);
    }

    ::v-deep .ant-table-column-title {
      font-size: 16px;
    }

    ::v-deep .ant-table-thead {
      tr {
        background: #efefef;

        th {
          border: none;
        }
      }
    }

    ::v-deep .ant-table-tbody {
      tr {
        &:not(:last-of-type) > td {
          border: none;
        }

        & > td {
          border-bottom: 1px solid #efefef;
        }
      }
    }

    ::v-deep .ant-table-thead > tr > th {
      background: #f4f5f7;
      border-bottom: none;
      font-weight: 500;
    }
  }

  .right-info {
    width: 242px;

    .message-box {
      min-height: 136px;
      background: url('~@/assets/userBg.png') no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      margin-bottom: 20px;

      .userInfo {
        position: relative;

        .editUser {
          display: flex;
          align-items: center;
          color: #FF6026;
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;

          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }

        &-left {
          display: flex;

          .ant-avatar {
            width: 44px;
            height: 44px;
          }

          .infoBox {
            margin-left: 8px;
            font-size: 12px;
            color: #595959;

            h3.userName {
              color: #595959;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.tags-list {
  width: 100%;
  margin-top: 10px;

  .ant-tag {
    margin-bottom: 6px;
    background-color: #fff;

    .anticon-close {
      opacity: 0;
    }

    &:hover {
      .anticon-close {
        opacity: 1;
      }
    }
  }

  .ant-tag-blue {
    color: #FF6026;
    border-color: #FF6026;
  }
}

::v-deep .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.notice-info {
  border-radius: 8px;
  border: 1px solid #ededf0;
  overflow: hidden;

  &-header {
    height: 38px;
    background: url('~@/assets/lineHeader.jpg') no-repeat;
    background-size: 100% 100%;
    padding: 0 30px;
    color: #000;
    font-weight: 500;
    line-height: 38px;
  }

  .content {
    padding: 20px 15px;

    ::v-deep .ant-tabs-small .ant-tabs-tab {
      font-size: 12px !important;
    }

    .messageBox-item {
      font-size: 12px;
      color: #131212;
      line-height: 22px;
    }
  }
}

::v-deep .ant-tag {
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 6px;

  &.normalTag {
    background: #fff;
    border: none;
    color: #595959;
  }

  &.moreTag {
    &:hover {
      color: #FF6026;
    }
  }
}

a.tagEdit {
  font-size: 12px;
  display: inline-block;
  position: relative;
  padding-left: 8px;

  &::before {
    display: inline-block;
    content: '';
    width: 1px;
    height: 14px;
    background: #ece7e7;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.userInfo-card {
  width: 100%;
  height: 104px;
  border-radius: 3px;
  background: #fff;
  padding: 23px 22px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .userInfo-right {
    padding-left: 2px;
    display: flex;
    align-items: center;

    .right-box {

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .username-info {
      display: flex;
      align-items: center;

      .username {
        font-weight: 500;
        font-size: 18px;
        color: #000000;
      }
    }

    .desc {
      //styleName: 14/常规;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000000a6;
    }

    .tag {
      border-radius: 2px;
      width: 52px;
      height: 22px;
      background: #ee4261;
      margin-left: 15px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #fff;
    }
  }

  .userInfo-left {
    display: flex;
    align-items: center;

    .action-info {
      display: flex;
      align-items: center;

      .icon {
        width: 18px;
        cursor: pointer;
      }
    }

    .btn {
      width: 112px;
      height: 38px;
      //styleName: 16/加粗;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin-left: 16px;
    }
  }
}

.space-title {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 15px;
}

.center-part {
  .part-container {
    display: flex;
    align-items: center;
    grid-gap: 0 15px;

    .card-box {
      width: 440px;
      height: 152px;
      border-radius: 3px;
      padding: 25px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.right-card {
        flex: 1 0 0;
        min-width: 0;
        width: auto;

        .data-card {
          background: transparent !important;
          flex: 1 0 0;
          min-width: 0;
          width: auto !important;
          cursor: pointer;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        .label {
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
        }

        .icon {
          width: 18px;
          cursor: pointer;
          // margin-left: 10px;
        }
      }

      ::v-deep .ant-tooltip-inner {
        width: 400px;
        border-radius: 8px;
      }

      .container-tooltip {
        width: 376px;

        .title {
          font-weight: 500;
          margin-bottom: 6px;
        }

        .desc-item {
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 2px;
        }
      }

      .data-view-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .data-card {
          width: 188px;
          height: 64px;
          border-radius: 3px;
          background: #f4f5f7;
          padding: 10px 0 0 12px;
          box-sizing: border-box;
          cursor: pointer;
        }

        .label-top {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #5e5e66;

          .tag {
            width: 12px;
          }
        }

        .num {
          color: #131212;
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
}

.bottom-info {
  background: #fff;
  padding: 14px 24px 24px;
  box-sizing: border-box;
  flex: 1 0 0;
  min-width: 0;
  border-radius: 3px;

  ::v-deep .ant-table-wrapper {
    min-height: calc(100vh - 600px);
  }

  ::v-deep .ant-tabs-tab {
    padding: 12px 0 20px;
    font-size: 16px;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    color: #131212;
    border: none;
    padding: 13px 16px 14px;
  }

  ::v-deep .ant-table-tbody > tr > td {
    padding: 13px 16px 14px;
  }

  .row_title {
    display: flex;
    align-items: center;
  }

  img.newIcon {
    width: 16px;
    margin-left: 6px;
  }
}

.tags-list {
  display: flex;
  align-items: center;
  grid-gap: 8px 8px;
  flex-wrap: wrap;
  height: 26px;
  &-item {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #666;
    line-height: 22px;
    height: 24px;
    text-align: center;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}
.editTag {
  width: 76px;
  cursor: pointer;
}
.right-box .username-info .username{
  width: 300px;
}
</style>
